.contact {
    font-family: $poppins;
    margin-top: 31px;
    padding-top: 116px;
    .checkout {
        &__form {
            &-message {
                min-height: 145px;
                width: 100%;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: center;
                align-content: center;
                box-shadow: 0 25px 25px rgba(0,0,0,0.16);
                margin-bottom: 50px;
                padding: 0 40px;
                color: #fff;
                font-size: 25px;
                font-weight: 600;
                display: none;
                @media(max-width: 575px) {
                    justify-content: center;
                    padding: 20px;
                    text-align: center;
                }
                &.active {
                    display: flex;
                }
                &.error {
                    background-color: #F6576E;
                }
                &.check {
                    background-color: #2D54A5;
                }
                &.succes {
                    background-color: #07A276;
                }
                img {
                    display: block;
                    margin-right: 30px;
                    @media(max-width: 575px) {
                        margin: 0 auto 20px;
                    }
                }
                p {
                    max-width: calc(100% - 100px);
                    width: 100%;
                    margin-bottom: 0;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: center;
                    align-items: center;
                    margin: 0;
                    @media(max-width: 575px) {
                        width: 100%;
                        margin: 0;
                        max-width: none;
                    }
                    .title {
                        display: block;
                        font-weight: 700;
                        width: 100%;
                    }
                    .subtitle {
                        width: 100%;
                        font-weight: 400;
                        font-size: 15px;
                    }
                }
            }
        }
    }
    &__title {
        font-size: 45px;
        font-weight: 700;
        margin-bottom: 14px;
        margin-top: 0px;
    }
    &__subtitle {
        font-size: 24px;
        font-weight: 700;
        padding-bottom: 10px;
        margin-bottom: 40px;
        border-bottom: 3px solid #004DFC;
    }
    &__form {
        textarea {
            width: 100%;
            height: 308px;
            border: 1px solid rgba(112,112,112,.3);
            border-radius: 6px;
            resize: none;
            margin-bottom: 30px;
            padding: 25px 18px;
            font-size: 15px;
            font-family: $poppins;
        }
        input[type="text"] {
            border-radius: 6px;
            box-shadow: none;
            padding: 25px 18px;
            font-size: 15px;
            font-family: $poppins;
        }
        &-submit {
            width: 100%;
            background-color: #004DFC;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 700;
            padding: 31px 0 25px 0;
            border: none;
            cursor: pointer;
            img {
                margin-right: 17px;
            }
        }
    }
    &__info {
        margin-top: 31px;
        padding: 46px 87px 112px 87px;
        height: auto;
        background-color: #F5F5F5;
        border-radius: 32px;
        @media(max-width: 540px) {
            padding: 25px;
        }
        @media(max-width: 400px) {
            padding: 15px;
        }
        &-title {
            margin-bottom: 30px;
            font-size: 24px;
            font-weight: 700;
        }
        &-subtitle {
            color: #1A1B1C;
            font-size: 17px;
            line-height: 30px;
            font-weight: 700;
        }
        &-content {
            color: #2A2E43;
            font-size: 17px;
            line-height: 30px;
        }
    }
}