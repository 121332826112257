* {
    -webkit-tap-highlight-color:transparent;
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
*:active, *:focus {
    outline:none
}
input::-moz-focus-inner, select::-moz-focus-inner, option::-moz-focus-inner, textarea::-moz-focus-inner {
    border:0;
    outline:none
}
img {
    max-width:100%;
    height:auto;
}
picture {
    max-width: 100%;
    height: auto;
}
html, body {
    margin:0;
    padding:0;
    font-weight: 400;
    color: #515151;
}
body {
    min-height: 100vh;
    position: relative;
    font-family: $poppins;
    text-transform: none;
    // Dodany padding dolny dla footera na pozycji absolutnej
    padding-bottom: 71px;
}
ul, ol {
    padding:0;
    margin:0;
    list-style:none
}
a {
    color: inherit;
    text-decoration:none;
    outline:none
}
a:link, a:hover, a:visited {
    text-decoration:none;
    outline:none;
    color:inherit;
}

.container.w {
    max-width: 1000px;
}
.container.w-1500 {
    max-width: 1500px;
}
.content {
    margin-top: 61px;
}
.course-unlock-message {
    margin-bottom: 10px;
    font-weight: 600;
    &.error {
        color: red;
    }
    &.success {
        color: #40B100;
    }
}

.mb-46 {
    margin-bottom: 46px;
}

.mb-70 {
    margin-bottom: 70px;
}

.d-none {
    display: none !important;
}
.d-block {
    display: block !important;
}
.d-xl-block {
    @media(min-width: 1200px) {
        display: block !important;
    }
}
.d-xl-none {
    @media(min-width: 1200px) {
        display: none !important;
    }
}