input {
    margin-bottom: 21px;
    width: 100%;
    box-shadow: $inputShadow;
    border-radius: 13px;
    font-family: $ubuntu;
    font-size: 16px;
    line-height: 25px;
    padding: 14px 23px 16px 23px;
    border: $formBorder;
    font-family: $lato;
}
input[type="submit"] {
    cursor: pointer;
    font-family: $lato;
}

.checkbox-wrapper {
    position: relative;
    user-select: none;
    input:checked + label::before {
        opacity: 1;
    }
    label {
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            box-shadow: $inputShadow;
            background-color: #fff;
            pointer-events: none;
            border: 1px solid #D8D8D8;
        }
        &::before {
            content: '';
            opacity: 0;
            transition: .3s all;
            background-image: url('../images/check-icon.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            top: 13px;
            left: 13px;
            transform: translate(-50%,-50%);
            width: 15px;
            height: 11px;
            z-index: 1;
            pointer-events: none;
        }
    }
    input {
        box-shadow: none;
        transform: scale(1.6);
        margin-right: 12px;
    }
}