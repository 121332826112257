.our-courses {
    font-family: $poppins;
    max-width: 1500px;
    margin: 0 auto;
    display: block;
    &.list {
        padding-top: 116px;
    }
    &__category-title {
        font-size: 33px;
        font-weight: 700;
        letter-spacing: -0.05em;
        padding-bottom: 15px;
        border-bottom: 3px solid #F4F4F4;
        margin-bottom: 40px;
    }
    &__title {
        font-size: 45px;
        font-weight: 700;
        margin-bottom: 70px;
        &.list {
            margin-bottom: 32px;
        }
    }
    &__item {
        box-shadow: 0 10px 30px rgba(0,0,0,.16);
        background-color: #fff;
        height: 100%;
        transition: .3s all ease-in-out;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        border-radius: 10px;
        &:hover {
            box-shadow: 0 20px 50px rgba(0,0,0,.41);
            transform: translate(0, -10px);
            .our-courses__item-title {
                color: #004DFC;
            }
            .our-courses__item-image {
                &::after {
                    opacity: 1;
                }
            }
            .our-courses__item-image-play {
                left: 50%;
            }
            .our-courses__item-image-play-icon {
                left: 50%;
            }
        }
        &-top {
            width: 100%;
        }
        &-image {
            margin-bottom: 32px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            padding-bottom: 56.25%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.8);
                opacity: 0;
                transition: .3s all ease-in-out;
            }
            img {
                max-width: 100%;
                display: block;
            }
            & > img {
                max-height: 100%;
                width: auto;
                max-height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                opacity: 0;
            }
            &-play {
                position: absolute;
                z-index: 1;
                background-color: #004DFC;
                width: 111px;
                height: 111px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                left: -100%;
                transition: .3s all ease-in-out;
                top: 50%;
                transform: translate(-50%,-50%);
                &-icon {
                    position: absolute;
                    z-index: 2;
                    width: 111px;
                    height: 111px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    left: 130%;
                    transition: .3s all ease-in-out;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    img {
                        margin-left: 15px;
                    }
                }
            }
            @keyframes AnimationBall {

            }
        }
        &-text {
            padding: 0 21px;
            margin-bottom: 32px;
        }
        &-title {
            font-size: 20px;
            line-height: 30px;
            font-weight: 700;
            margin-bottom: 16px;
            transition: .3s all ease-in-out;
        }
        &-description {
            font-size: 16px;
            line-height: 27px;
            margin-bottom: 28px;
        }
        &-bottom {
            width: 100%;
            &-price {
                font-size: 20px;
                line-height: 30px;
            }
        }
        &-more {
            padding-top: 19px;
            padding-bottom: 21px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            font-size: 14px;
            line-height: 25px;
            font-weight: 500;
            padding-top: 19px;
            margin: 0 21px;
            border-top: 1px solid rgba(26,27,28,.1);
            img {
                margin-right: 13px;
            }
            &-author {
                display: flex;
                align-items: center;
                margin-right: 5px;
            }
            &-time {
                display: flex;
                align-items: center;
                margin-right: 5px;
            }
            &-episodes {
                display: flex;
                align-items: center;
            }
        }
    }
}
