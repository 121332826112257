@charset "UTF-8";

@import 'utils/variables';

@import 'base/typography';
@import 'base/reset';

// @import 'components/buttons';
// @import 'components/dropdowns';
// @import 'components/articles';
@import "components/player";
@import "components/avt-popup";

@import 'layout/header';
@import 'layout/footer';
@import 'layout/forms';
// @import 'layout/pagination';

// @import 'pages/home';
@import 'pages/single';
@import 'pages/single-course';
// @import 'pages/question';

@import 'vendors/bootstrap';
@import "vendors/simplebar";

body {
    font-family: 'Poppins',sans-serif;
    color: #1A1B1C;
}
.content {
    margin-top: 0;
}
@import 'pages/home-new';
@import 'pages/new-single-course';
@import 'components/modules/our-courses';
@import 'pages/contact';
