.single-course {
    &__movie-btn {
        background: linear-gradient(to right, #117567 0%, #25aaa6 100%);
        min-height: 300px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin-top: 30px;
        color: #fff;
        font-size: 32px;
        font-weight: 700;
        letter-spacing: -0.032em;
        cursor: pointer;
        img {
            width: 32px;
            margin-right: 24px;
        }
    }
    &__movie-close {
        position: absolute;
        top: 15px;
        right: 15px;
        color: #fff;
        z-index: 5;
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        font-weight: 500;
        font-size: 15px;
        font-family: 'Roboto', sans-serif;
        &-text {
            opacity: 0;
            margin-right: 15px;
            transition: opacity .2s ease-in-out;
        }
        &-btn {
            position: relative;
            width: 24px;
            height: 24px;
            span {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                height: 2px;
                transform-origin: 50% 50%;
                margin: auto;
                background-color: #fff;
                z-index: 4;
                &:first-child {
                    transform: rotate(45deg);
                }
                &:last-child {
                    transform: rotate(-45deg);
                }
            }
        }
        &:hover {
            .single-course__movie-close-text {
                opacity: 1;
            }
        }
    }
    &__movie-popup {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 999999;
        opacity: 0;
        pointer-events: none;
        transition: opacity .2s ease-in-out;
        background-color: #000;
        &.open {
            pointer-events: all;
            opacity: 1;
        }
    }
    &__top {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 38px;
        border-bottom: $lightBorder;
        margin-bottom: 14px;
        &-image {
            max-width: 100%;
            width: 100%;
            margin-bottom: 30px;
            @media(min-width: $md) {
                max-width: 265px;
            }
            img {
                border-radius: 13px;
                border: 1px solid #D8D8D8;
                box-shadow: $inputShadow;
                @media(min-width: $md) {
                    margin-right: 30px;
                }
            }
        }
        &-text {
            max-width: 100%;
            width: 100%;
            @media(min-width: $md) {
                max-width: calc(100% - 265px);
            }
            &-title {
                font-size: 32px;
                line-height: 32px;
                font-weight: 700;
                letter-spacing: -0.03em;
                margin: 0;
                margin-bottom: 19px;
            }
            &-introtext {
                font-size: 17px;
                line-height: 27px;
                font-family: $poppins;
                margin-bottom: 29px;
            }
        }
    }
    &__box {
        display: flex;
        align-items: center;
        border: 2px solid #23A49F;
        border-radius: 15px;
        margin-bottom: 33px;
        position: relative;
        &.home-box {
            margin-top: 60px;
            margin-bottom: 0px;
            p {
                margin-bottom: 0px;
            }
            .single-course__box-text {
                padding: 15px;
            }
        }
        &-image {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
            background-color: #23A49F;
            width: 100%;
            height: 100%;
            max-width: 191px;
            margin-right: 32px;
            position: absolute;
            left: 0;
            top: 0;
            @media(max-width: 600px) {
                display: none;
            }
            img {
                display: block;
            }
        }
        &-text {
            font-size: 15px;
            line-height: 19px;
            display: inline-block;
            padding: 23px 19px 6px 223px;
            color: #515151;
            @media(max-width: 600px) {
                padding: 15px;
            }
            p {
                margin-top: 0px;
                margin-bottom: 20px;
            }
            a {
                color: #21A09A;
                font-weight: 700;
            }
        }
    }
    &__info {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        font-size: 14px;
        line-height: 16px;
        font-family: $poppins;
        margin-bottom: 60px;
        &.price {
            justify-content: space-between;
        }
        &-message {
            width: 100%;
            margin-top: 30px;
            display: none;
            font-size: 20px;
            font-weight: 500;
            &.error {
                color: red;
            }
            &.success {
                color: #40B100;
            }
            &.open {
                display: block;
            }
        }
        &-form {
            display: flex;
            align-items: center;
            border-radius: 13px;
            box-shadow: 0 3px 6px rgba(0,0,0,.44);
            padding: 15px 16px;
            &-key {
                margin-right: 16px;
            }
            &-title {
                font-size: 16px;
                line-height: 25px;
                font-weight: 700;
                margin-right: 23px;
                color: #000;
                user-select: none;
            }
            &-input {
                position: relative;
                input {
                    box-shadow: none;
                    border: none;
                    border-radius: 0px;
                    border-bottom: 1px solid rgba(112,112,112,.3);
                    padding: 5px 0;
                    margin-bottom: 0;
                    height: 22px;
                }
                button[type="submit"] {
                    position: absolute;
                    right: 0;
                    bottom: 6px;
                    border: none;
                    outline: none;
                    background: transparent;
                    margin: 0;
                    padding: 0;
                    cursor: pointer;
                    img {
                        display: block;
                    }
                }
            }
        }
        &-about {
            display: flex;
            flex-wrap: wrap;
            font-size: 14px;
            line-height: 25px;
            font-family: $poppins;
            @media(max-width: 800px) {
                max-width: 100%;
                width: 100%;
                margin-bottom: 20px;
            }
            &-author {
                margin-right: 50px;
                @media(max-width: 800px) {
                    margin-bottom: 10px;
                    width: 100%;
                }
            }
            &-time {
                margin-right: 55px;
                @media(max-width: 800px) {
                    margin-bottom: 10px;
                    width: 100%;
                }
            }
            &-type {
                @media(max-width: 800px) {
                    margin-bottom: 10px;
                    width: 100%;
                }
            }
        }
        &-price {
            @media(max-width: 800px) {
                max-width: 100%;
                width: 100%;
            }
            &-free {
                color: #518F1B;
                font-weight: 700;
                width: 100%;
                text-align: right;
            }
            &-pay {
                font-size: 16px;
                line-height: 25px;
                font-weight: 700;
                font-family: $poppins;
                background: $blueGradient;
                color: #fff;
                cursor: pointer;
                box-shadow: $btnShadow;
                border-radius: 13px;
                display: flex;
                align-items: center;
                position: relative;
                &:before {
                    background-image: $hoverGradient;
                    content: "";
                    display: block;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    width: 100%;
                    z-index: 1;
                    transition: opacity .5s;
                    border-radius: 13px;
                }
                &:hover {
                    &:before {
                        opacity: 1;
                    }
                }
                a {
                    padding: 8px 14px 12px 15px;
                    display: block;
                    z-index: 2;
                }
                img {
                    margin-right: 15px;
                }
            }
            &-purchased {
                display: flex;
                align-items: center;
                border: 2px solid #40B100;
                padding: 13px 19px;
                border-radius: 13px;
                box-shadow: 0 3px 6px rgba(0,0,0,.44);
                img {
                    margin-right: 15.5px;
                }
                .text {
                    font-size: 16px;
                    line-height: 25px;
                    font-weight: 700;
                    font-family: $poppins;
                    color: #000;
                }
            }
        }
    }
    &__fulltext {
        font-size: 17px;
        line-height: 27px;
        font-family: $poppins;
        margin-bottom: 72px;
        h2 {
            font-size: 32px;
            line-height: 32px;
            font-weight: 400;
            letter-spacing: -0.03em;
        }
        ol {
            list-style: decimal;
            padding-left: 25px;
        }
        ul {
            font-size: 16px;
            line-height: 27px;
            font-family: $poppins;
            &.fulltext-list {
                li {
                    &::before {
                        background-color: #117567;
                        border: none;
                        width: 10px;
                        height: 10px;
                    }
                }
            }
            li {
                padding-left: 34px;
                position: relative;
                &::before {
                    content: '';
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    left: 10px;
                    top: 10px;
                    border: 1px solid #707070;
                    border-radius: 50%;
                    background-color: #fff;
                }

            }
        }
        .free-lessons {
            padding-bottom: 15px;
            margin-bottom: 20px;
            border-bottom: $lightBorder;
            max-width: 450px;
            @media(min-width: $md) {
                float: right;
                margin-left: 35px;
            }
            &__title {
                font-size: 27px;
                line-height: 25px;
                color: #fff;
                padding: 10px 25px;
                width: 100%;
                background: $blueGradient;
                margin-bottom: 14px;
            }
            &__description {
                font-size: 16px;
                font-family: $poppins;
                margin-bottom: 14px;
            }
            &__list {
                font-size: 16px;
                line-height: 27px;
                font-family: $poppins;
                &-item {
                    padding-left: 34px;
                    position: relative;
                    &::before {
                        content: '';
                        width: 8px;
                        height: 8px;
                        position: absolute;
                        left: 10px;
                        top: 10px;
                        border: 1px solid #707070;
                        border-radius: 50%;
                        background-color: #fff;
                    }

                }
            }
        }
        .buy-course-btn {
            font-size: 25px;
            line-height: 39px;
            font-weight: 700;
            font-family: $poppins;
            color: #fff;
            background: $blueGradient;
            padding: 13px 15px 20px 15px;
            box-shadow: $btnShadow;
            display: block;
            margin: 0 auto;
            cursor: pointer;
            max-width: 272px;
            text-align: center;
            border-radius: 13px;
            margin-top: 52px;
            position: relative;
            & > a {
                z-index: 2;
                position: relative;
            }
            &:before {
                background-image: $hoverGradient;
                content: "";
                display: block;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                width: 100%;
                z-index: 1;
                transition: opacity .5s;
                border-radius: 13px;
            }
            &:hover {
                &:before {
                    opacity: 1;
                }
            }
        }
        .watch-course {
            font-size: 32px;
            line-height: 25px;
            font-weight: 700;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 300px;
            width: 100%;
            height: 100%;
            color: #fff;
            background: $blueGradient;
            letter-spacing: -0.03em;
            margin-bottom: 51px;
            cursor: pointer;
            @media(max-width: $sm) {
                font-size: 22px;
                line-height: 20px;
            }
            img {
                margin-right: 24px;
                @media(max-width: $sm) {
                    max-width: 25px;
                }
            }
        }
    }
}


.video-popup {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    left: -100%;
    background-color: #000;
    transition: .3s all;
    z-index: 100;
    .video {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        iframe {
            max-height: 100%;
            max-width: 100%;
        }
    }
    &.show {
        left: 0;
    }
    &__close {
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 15px;
        padding: 15px;
    }
}
