// Poppins fonts - START

    @font-face {
        font-family: 'Poppins';
        src: url('https://cdn.avt.pl/fonts/PoppinsRegular.eot');
        src: url('https://cdn.avt.pl/fonts/PoppinsRegular.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/PoppinsRegular.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/PoppinsRegular.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/PoppinsRegular.svg') format('svg');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }


    @font-face {
        font-family: 'Poppins';
        src: url('https://cdn.avt.pl/fonts/PoppinsBold.eot');
        src: url('https://cdn.avt.pl/fonts/PoppinsBold.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/PoppinsBold.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/PoppinsBold.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/PoppinsBold.svg') format('svg');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
      font-family: 'Poppins';
      src: url('https://cdn.avt.pl/fonts/PoppinsMedium.eot');
      src: url('https://cdn.avt.pl/fonts/PoppinsMedium.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.avt.pl/fonts/PoppinsMedium.woff2') format('woff2'),
      url('https://cdn.avt.pl/fonts/PoppinsMedium.woff') format('woff'),
      url('https://cdn.avt.pl/fonts/PoppinsMedium.svg') format('svg');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
  }

// Poppins fonts - END