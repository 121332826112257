.magazine {
    margin-bottom: 25px;
    &-article {
        position: relative;
        border-radius: 13px;
        img {
            border-radius: 13px;
        }
        @media(max-width: $md) {
            margin-bottom: 50px;
        }
        &__text {
            position: absolute;
            top: 0;
            left: 24px;
            z-index: 1;
            padding: 21px;
            &-title {
                font-size: 38px;
                line-height: 39px;
                font-weight: 700;
                color: #fff;
                margin-bottom: 28px;
                max-width: 350px;
                letter-spacing: -0.03em;
                @media(max-width: 460px) {
                    font-size: 28px;
                    line-height: 29px;
                }
            }
            &-description {
                font-size: 19px;
                line-height: 27px;
                color: #fff;
                max-width: 210px;
                font-weight: 400;
                font-family: $lato;
            }
        }
    }
    &__logged {
        background-color: #E6E6E6;
        height: 100%;
        text-align: center;
        padding: 59px 15px 45px 15px;
        font-size: 17px;
        line-height: 25px;
        border-radius: 13px;
        &-title {
            font-weight: 700;
            font-family: $lato;
        }
        &-info {
            margin-bottom: 15px;
            font-family: $lato;
        }
        &-btn {
            margin-top: 68px;
            padding: 10px 43px;
            background-color: #fff;
            display: inline-block;
            margin: 68px auto 0 auto;
            border-radius: 13px;
            border: 1px solid #CFCFCF;
            cursor: pointer;
        }
    }
    &__login {
        &-form {
            font-size: 14px;
            line-height: 25px;
            &-remmember {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding-bottom: 50px;
                margin-bottom: 18px;
                border-bottom: $lightBorder;
                white-space: nowrap;
                font-family: $lato;
                @media(max-width: 1000px) {
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                }
                @media(max-width: $md) {
                    padding-bottom: 40px;
                    margin-bottom: 18px;
                }
                input {
                    width: 24px;
                    margin: 0;
                    margin-right: 11px;
                }
                .blue-btn {
                    padding: 8px 25px 12px 25px;
                    @media(max-width: 1000px) {
                        margin: 20px auto auto 0;
                    }
                }
            }
            &-text {
                @media(max-width: 1000px) {
                    max-width: 50%;
                    flex: 0 0 50%;
                }
            }
            &-lost {
                margin-left: 31px;
                text-decoration: underline;
                @media(max-width: 1000px) {
                    margin-left: 0;
                    max-width: 50%;
                    flex: 0 0 50%;
                }
            }
        }
    }
    &__register {
        .subpage-title {
            margin-bottom: 14px;
        }
        &-description {
            font-size: 14px;
            line-height: 19px;
            margin-bottom: 20px;
            font-family: $lato;
        }
    }
    &-single {
        display: flex;
        flex-wrap: wrap;
        &__image {
            max-width: 100%;
            margin-bottom: 50px;
            @media(min-width: $md) {
                max-width: 190px;
                margin-right: 50px;
            }
            img {
                box-shadow: $imageShadow;
            }
        }
        &__files {
            max-width: 100%;
            width: 100%;
            @media(min-width: $md) {
                max-width: calc(100% - 240px);
                width: 100%;
            }
            &-wrapper {
                margin-bottom: 78px;
            }
            &-item {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 50px;
                &-image {
                    max-width: 100%;
                    width: 100%;
                    margin-bottom: 30px;
                    @media(min-width: $sm) {
                        max-width: 215px;
                        margin-bottom: 0px;
                    }
                    img {
                        border: 1px solid #D8D8D8;
                        box-shadow: $inputShadow;
                        border-radius: 13px;
                    }
                }
                &-text {
                    max-width: 100%;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: space-between;
                    @media(min-width: $sm) {
                        max-width: calc(100% - 215px);
                    }
                    &-title {
                        font-size: 27px;
                        line-height: 29px;
                        font-weight: 700;
                        max-width: 100%;
                        width: 100%;
                        margin-bottom: 20px;
                        @media(min-width: $sm) {
                            margin-bottom: 0px;
                        }
                    }
                    &-download {
                        font-size: 14px;
                        line-height: 25px;
                        img:nth-of-type(1) {
                            margin-right: 14px;
                        }
                        img:nth-of-type(2) {
                            margin-right: 10px;
                        }
                    }
                }
                &-bottom {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    max-width: 100%;
                    width: 100%;
                    margin-bottom: 6px;
                    .white-btn {
                        @media(max-width: $sm) {
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
        &__title {
            font-size: 27px;
            line-height: 25px;
            font-weight: 700;
            margin-bottom: 12px;
            @media(max-width:640px) {
                font-size: 22px;
                line-height: 26px;
            }
        }
        &__file {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 38px;
            &-download {
                display: flex;
                align-items: center;
                font-size: 14px;
                line-height: 25px;
                font-family: $lato;
                margin-bottom: 20px;
                img {
                    margin-right: 10px;
                }
            }
        }
    }
}