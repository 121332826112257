.main-top {
    &__wrapper {
        padding-bottom: 150px;
        position: relative;
        @media(min-width: 767px) {
            background-image: url('/assets-kursy/images/top-image.jpg');
            background-size: cover;
            background-position: center;
            padding-top: 207px;
            padding-bottom: 363px;
        }
        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 100%;
            left: 0;
            background: linear-gradient(to bottom, rgba(244,244,244,1) 0%,rgba(244,244,244,0));
            pointer-events: none;
        }
    }
    &__image {
        position: relative;
        margin: 0 -15px;
        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 100%;
            left: 0;
            background: linear-gradient(to bottom, rgba(244,244,244,1) 0%,rgba(244,244,244,0));
            pointer-events: none;
        }
        img {
            display: block;
        }
        @media(min-width: 767px) {
            display: none;
        }
    }
    &__text {
        font-family: $poppins;
        max-width: 700px;
        position: relative;
        &-title {
            font-size: 60px;
            line-height: 68px;
            font-weight: 700;
            margin-bottom: 24px;
            @media(max-width: 600px) {
                font-size: 40px;
                line-height: 48px;
            }
            @media(max-width: 400px) {
                font-size: 30px;
                line-height: 38px;
            }
        }
        &-description {
            font-size: 16px;
            line-height: 27px;
            margin-bottom: 37px;
            max-width: 450px;
        }
        &-btn {
            padding: 29px 48px;
            display: inline-block;
            font-size: 16px;
            font-weight: 700;
            box-shadow: 0 20px 60px rgba(0,0,0,.16);
            border-radius: 6px;
            transition: .3s all ease-in-out;
            &:hover {
                background-color: #1A1B1C;
                color: #fff;
            }
        }
    }
}

.main-blue {
    background-color: #0F1215;
    color: #fff;
    display: flex;
    padding: 38px 125px 38px 64px;
    margin-right: 139px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    box-shadow: 0 60px 99px rgba(0,0,0,.16);
    margin-bottom: 100px;
    font-family: $poppins;
    position: relative;
    @media(max-width: 1650px) {
        padding: 38px 64px 38px 64px; 
    }
    @media(max-width: 1300px) {
        margin: 0 15px 100px 15px;
        border-radius: 40px;
    }
    @media(max-width: 1200px) {
        padding: 35px;
    }
    @media(max-width: 850px) {
        flex-wrap: wrap;
    }
    @media(max-width: 450px) {
        padding: 15px;
    }
    &.on-list {
        margin-top: 130px;
    }
    &__image {
        background-image: url('/assets-kursy/images/blue-background-image.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 60%;
        flex: 0 0 60%;
        height: 568px;
        @media(max-width: 1600px) {
            max-width: 50%;
            flex: 0 0 50%;
        }
        @media(max-width: 850px) {
            max-width: 100%;
            flex: 0 0 100%;
            height: auto;
            padding: 100px 15px;
            margin-bottom: 20px;
        }
    }
    &__hidden-price {
        display: none;
        font-size: 20px;
        margin-bottom: 20px;
        @media(max-width: 615px) {
            display: block;
        }
    }
    &__text {
        max-width: 40%;
        flex: 0 0 40%;
        padding-left: 123px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        @media(max-width: 1810px) {
            padding-left: 60px;
        }
        @media(max-width: 1600px) {
            max-width: 50%;
            flex: 0 0 50%;
        }
        @media(max-width: 1200px) {
            padding-left: 30px;
        }
        @media(max-width: 850px) {
            max-width: 100%;
            flex: 0 0 100%;
            padding-left: 0px;
        }
        &-recommended {
            opacity: .33;
            font-size: 15px;
            text-transform: uppercase;
            width: 100%;
            letter-spacing: 0.200em;
            margin-bottom: 8px;
        }
        &-title {
            font-size: 30px;
            font-weight: 700;
            width: 100%;
            margin-bottom: 21px;
            @media(max-width: 450px) {
                font-size: 25px;
            }
        }
        &-info {
            display: flex;
            align-items: center;
            margin-left: 10px;
            flex-wrap: wrap;
            font-size: 14px;
            line-height: 25px;
            font-weight: 700;
            margin-bottom: 32px;
            @media(max-width: 615px) {
                margin-left: 0px;
                margin-bottom: 20px;
            }
            img {
                margin-right: 13px;
            }
            &-author {
                display: flex;
                align-items: center;
                margin-right: 29px;
                @media(max-width: 500px) {
                    max-width: 100%;
                    width: 100%;
                    margin-bottom: 8px;
                }
            }
            &-time {
                display: flex;
                align-items: center;
                margin-right: 29px;
                @media(max-width: 500px) {
                    max-width: 100%;
                    width: 100%;
                    margin-bottom: 8px;
                }
            }
            &-counter {
                display: flex;
                align-items: center;
                @media(max-width: 500px) {
                    max-width: 100%;
                    width: 100%;
                    margin-bottom: 8px;
                }
            }
        }
        &-introtext {
            font-size: 16px;
            line-height: 27px;
            opacity: .66;
            margin-bottom: 38px;
        }
        &-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            &-btn {
                font-size: 17px;
                font-weight: 700;
                background-color: #0055FB;
                color: #fff;
                padding: 25px 47px;
                border-radius: 6px;
                transition: .3s all ease-in-out;
                @media(max-width: 450px) {
                    margin: 0 auto;
                }
                &:hover {
                    background-color: #fff;
                    color: #0055FB;
                }
            }
            &-price {
                font-size: 20px;
                @media(max-width: 615px) {
                    display: none;
                }
            }
        }
    }
}

.how-this-works {
    max-width: 1100px;
    padding-right: 15px;
    padding-left: 15px;
    margin: 0 auto;
    font-family: $poppins;
    margin-bottom: 122px;
    &__title {
        font-size: 45px;
        font-weight: 700;
        margin-bottom: 70px;
        text-align: center;
    }
    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 66px 52px 66px 106px;
        box-shadow: 0 30px 99px rgba(0,0,0,.16);
        margin-bottom: 30px;
        background-color: #fff;
        border-radius: 25px;
        position: relative;
        @media(max-width: 850px) {
            padding: 30px;
        }
        @media(max-width: 580px) {
            flex-wrap: wrap;
        }
        a {
            color: #004DFC;
            font-weight: 700;
        }
        &-number {
            font-size: 67px;
            font-weight: 700;
            opacity: .22;
            padding-right: 80px;
            @media(max-width: 650px) {
                padding-right: 30px;
            }
            @media(max-width: 580px) {
                position: absolute;
                top: 15px;
                left: 15px;
            }
            @media(max-width: 470px) {
                font-size: 37px;
            }
        }
        &-text {
            padding-right: 85px;
            color: #1A1B1C;
            font-weight: 500;
            flex-grow: 1;
            @media(max-width: 650px) {
                padding-right: 30px;
            }
            @media(max-width: 580px) {
                max-width: 100%;
                width: 100%;
                padding: 0 60px;
                order: 2;
                text-align: center;
            }
            @media(max-width: 470px) {
                padding: 0;
            }
        }
        &-image {
            max-width: 230px;
            width: 100%;
            align-items: flex-end;
            @media(max-width: 580px) {
                max-width: 100%;
                width: 100%;
                padding: 0 60px;
                order: 1;
                img {
                    margin: 0 auto;
                    display: block;
                }
            }
        }
    }
}

