.avt-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background-color: #000000;
    transition: transform .35s ease-in-out;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 50px 15px;
    max-height: 100vh;
    z-index: 999999;
    @media(min-width: 768px) {
        padding: 50px 30px;
    }
    @media(min-width: 1366px) {
        padding: 50px;
    }
    &.zoom {
        padding: 0;
        overflow: hidden;
        .avt-popup__close {
            right: 30px;
        }
    }
    &.--img {
        .avt-popup__image-wrap {
            width: 100%;
            max-width: 100%;
            &:after {
                display: none;
            }
        }
    }
    &__image-wrap {
        width: 100%;
        position: relative;
        background-repeat: no-repeat;
        background-position: -99999999px -99999999px;
        margin-bottom: 30px;
        height: auto;
        @media(min-width: 576px) {
            padding: 0 15px;
        }
        @media(min-width: 768px) {
        }
        @media(min-width: 1025px) {
            overflow: hidden;
            height: 100%;
            max-width: 85%;
            margin-bottom: 0;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-image: inherit;
            filter: blur(15px) brightness(80%);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        &:after {
            content: "";
            position: absolute;
            bottom: -15px;
            left: -15px;
            right: -15px;
            height: 30%;
            background: linear-gradient(180deg, rgba(153,218,255,0) 0%, rgba(0,0,0,1) 100%);
            pointer-events: none;
        }
        &.zoom {
            margin-top: 0;
            overflow: auto;
            max-width: none;
            max-height: none;
            position: relative;
            z-index: 3;
            .avt-popup__image {
                cursor: zoom-out;
                max-width: none;
                max-height: none;
            }
            .avt-popup__counter,
            .avt-popup__prev,
            .avt-popup__next,
            .avt-popup__description {
                display: none;
            }
            &:after {
                display: none;
            }
        }
    }
    &__image {
        max-height: 100%;
        max-width: 100%;
        height: auto;
        width: auto;
        margin: 0 auto;
        display: block;
        cursor: zoom-in;
        box-shadow: 3px 6px 16px rgba(0,0,0,0.16);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: relative;
        margin: 2.5% auto 30px;
        @media(min-width: 1025px) {
            max-width: calc(100% - 30px);
            position: absolute;
            margin: auto;
        }
    }
    &__description {
        width: auto;
        text-align: center;
        color: #fff;
        bottom: 0;
        text-align: center;
        width: 100%;
        z-index: 3;
        position: relative;
        margin-bottom: 60px;
        @media(min-width: 576px) {
            text-align: left;
        }
        @media(min-width: 1025px) {
            left: 15px;
            margin-bottom: 0;
            position: absolute;
            max-width: calc(100% - 165px);
        }
        @media(min-width: 1366px) {
            max-width: 100%;
            text-align: center;
            bottom: 45px;
        }
        .title {
            margin-bottom: 10px;
            font-weight: 700;
            font-size: 36px;
            line-height: 39px;
            @media(min-width: 576px) {
                font-size: 49px;
                line-height: 53px;
            }
        }
        .introtext {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
        }
    }
    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        z-index: 3;
        &:after, &:before {
            content: "";
            display: block;
            width: 30px;
            height: 2px;
            background-color: #fff;
            position: absolute;
            top: -999999px;
            right: -999999px;
            bottom: -999999px;
            left: -999999px;
            margin: auto;
        }
        &:before {
            transform: rotate(-45deg);
        }
        &:after {
            transform: rotate(45deg);
        }
    }
    &.open {
        transform: translateX(0);
    }
    &__gallery {
        text-align: center;
        overflow: auto;
        max-height: 100%;
        width: 100%;
        padding-bottom: 30px;
        white-space: normal;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media(min-width: 576px) {
            display: block;
            white-space: nowrap;
            padding: 0 15px;
        }
        @media(min-width: 1025px) {
            max-width: 15%;
            padding-bottom: 0;
            white-space: normal;
        }
        &-item {
            margin-bottom: 15px;
            cursor: pointer;
            background-size: cover;
            background-position: center;
            transition: transform .35s ease-in-out;
            display: inline-block;
            margin: 0 5px;
            width: calc((100% / 3) - 10px);
            padding-bottom: 30%;
            margin-bottom: 10px;
            @media(min-width: 576px) {
                width: 30vw;
                margin-bottom: 0;
            }
            @media(min-width: 768px) {
                width: 20vw;
                padding-bottom: 15%;
            }
            @media(min-width: 1025px) {
                display: block;
                padding-bottom: 65%;
                width: 100%;
                margin: 0 0 15px;
            }
            &:first-child {
                @media(min-width: 576px) {
                    margin-left: 0;
                }
            }
            &:last-child {
                @media(min-width: 576px) {
                    margin-right: 0;
                }
            }
            &.active {
                box-shadow: 0px 0px 15px rgba(255,255,255,0.16);
            }
        }
    }
    &__next, &__prev {
        position: absolute;
        width: 35px;
        height: 30px;
        bottom: 0;
        margin: auto;
        cursor: pointer;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 3;
        background-size: contain;
    }
    &__next {
        right: 0;
        background-image: url("/assets-kursy/images/right.svg");
    }
    &__prev {
        background-image: url("/assets-kursy/images/left.svg");
        left: 0;
        right: auto;
        @media(min-width: 576px) {
            left: auto;
            right: 115px;
        }
    }
    &__counter {
        bottom: 0;
        color: #fff;
        z-index: 3;
        user-select: none;
        font-size: 16px;
        line-height: 30px;
        position: relative;
        width: 100%;
        pointer-events: none;
        position: absolute;
        text-align: center;
        @media(min-width: 576px) {
            right: 55px;
            text-align: right;
        }
        &-total {
            display: inline;
        }
        &-current {
            display: inline;
            &:after {
                content: "/";
            }
        }
    }
}
