.course-single {
    font-family: $poppins;
    padding-top: 116px;
    &__title {
        font-size: 35px;
        font-weight: 700;
        margin-bottom: 28px;
        @media(max-width: 700px) {
            font-size: 25px;
        }
    }
    &__image {
        background-image: url('/assets-kursy/images/blue-background-image.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 400px;
        background-color: #1A1B1C;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        & > img {
            max-height: 100%;
            width: auto;
        }
        &.unlocked-course {
            cursor: pointer;
        }
        &-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            &-icon {
                width: 90px;
                height: 90px;
                background-color: #004DFC;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                margin-bottom: 23px;
                img {
                    max-width: 40px;
                    margin-left: 14px;
                }
            }
            &-text {
                max-width: 100%;
                width: 100%;
                color: #fff;
                font-size: 30px;
                font-weight: 700;
                text-align: center;
            }
        }
    }
    &__locked {
        padding: 40px 55px 44px 55px;
        border: 2px solid #E2E2E2;
        margin-bottom: 30px;
        position: relative;
        @media(max-width: 450px) {
            padding: 15px;
        }
        &-steps {
            max-width: 360px;
            max-height: 390px;
            width: 100%;
            height: 100%;
            background-color: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            box-shadow: 0 40px 60px rgba(0,0,0,.16);
            border-radius: 8px;
            opacity: 0;
            transition: .3s all ease-in-out;
            z-index: 4;
            overflow: hidden;
            pointer-events: none;
            transform: translate(-50%,-50%);
            @media(min-width: 1200px) {
                margin: 41px 55px 86px 55px;
                top: 0;
                left: 0;
                transform: translate(0,0);
            }
            &.show {
                opacity: 1;
                .course-single__locked-steps-step {
                    pointer-events: all;
                }
            }
            &-step {
                background-color: #fff;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                padding: 27px 26px 26px 28px;
                pointer-events: none;
                // max-height: 390px;
                &.active {
                    &[data-step="3"] {
                        margin-left: 0;
                    }
                    &[data-step="2"] {
                        margin-left: 0;
                    }
                }
                &.inactive {
                    &[data-step="3"] {
                        margin-left: -360px;
                    }
                    &[data-step="2"] {
                        margin-left: -360px;
                    }
                    &[data-step="1"] {
                        margin-left: -360px;
                    }
                }
                &[data-step="1"] {
                    transition: .3s all ease-in-out;
                }
                &[data-step="3"] {
                    margin-left: 360px;
                    transition: .3s all ease-in-out;
                    .course-single__locked-steps-step-image {
                        margin-top: -20px;
                    }
                }
                &[data-step="2"] {
                    margin-left: 360px;
                    transition: .3s all ease-in-out;
                    .course-single__locked-steps-step-image {
                        margin-top: -25px;
                        margin-bottom: -30px;
                    }
                }
                &-top {
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 14.5px;
                    margin-bottom: 12.5px;
                    border-bottom: 1px solid #E2E2E2;
                    &-text {
                        font-size: 15px;
                        font-weight: 700;
                    }
                    &-close {
                        cursor: pointer;
                        img {
                            pointer-events: none;
                        }
                    }
                }
                &-counter {
                    font-size: 15px;
                    font-weight: 700;
                    margin-bottom: 16px;
                    text-align: center;
                }
                &-image {
                    text-align: center;
                    margin-bottom: 11px;
                }
                &-description {
                    font-size: 14px;
                    line-height: 22px;
                    font-weight: 500;
                    text-align: center;
                    margin: 0 auto 20px auto;
                    a {
                        color: #004DFC;
                    }
                }
                &-btn {
                    width: 100%;
                    background-color: #004DFC;
                    border-radius: 4px;
                    padding: 14px 0;
                    text-align: center;
                    color: #fff;
                    font-size: 15px;
                    font-weight: 700;
                    cursor: pointer;
                    transition: .3s all ease-in-out;
                    &:hover {
                        background-color: #1A1B1C;
                    }
                }
            }
        }
        &-price {
            font-size: 25px;
            line-height: 38px;
            font-weight: 500;
            margin-bottom: 20px;
        }
        a.course-single__locked-purchase-uk {
            color: #fff;
        }
        &-purchase-uk {
            background-color: #004DFC;
            width: 100%;
            padding: 29px 0 25px 0;
            color: #fff;
            font-size: 16px;
            font-weight: 700;
            text-align: center;
            border-radius: 6px;
            margin-bottom: 16px;
            cursor: pointer;
            display: block;
            transition: .3s all;
            &:hover {
                background-color: #1A1B1C;
            }
        }
        &-unlock-course {
            margin-bottom: 27px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 29px 0;
            border: 2px solid #004DFC;
            border-radius: 6px;
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;
            img {
                margin-right: 10px;
            }
        }
        &-info {
            &-item {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                &-text {
                    font-size: 14px;
                    line-height: 20px;
                    color: #7A7A7A;
                }
                &-image {
                    margin-right: 16px;
                    margin-top: 3px;
                    max-width: 20px;
                    flex: 0 0 20px;
                    img {
                        width: 100%;
                        display: block;
                    }
                }
            }
        }
        &-help {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 25px;
            font-weight: 600;
            text-decoration: underline;
            cursor: pointer;
            img {
                margin-right: 16px;
            }
        }
    }
    &__unlocked {
        padding: 34px 40px;
        border: 2px solid #E2E2E2;
        border-top: 6px solid #004DFC;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-family: $poppins;
        &-image {
            margin-right: 23px;
        }
        &-text {
            &-title {
                font-size: 16px;
                line-height: 30px;
                font-weight: 700;
                margin-bottom: 11px;
            }
            &-name {
                font-size: 16px;
                line-height: 25px;
                color: #1A1B1C;
            }
            &-email {
                font-size: 14px;
                line-height: 25px;
                color: #7A7A7A;
            }
        }
    }
    &__toc {
        padding: 40px 55px 44px 55px;
        border: 2px solid #E2E2E2;
        margin-bottom: 30px;
        &-title {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 3px;
        }
        &.banner-field {
            ol {
                counter-reset: sections;
                li {
                    display: inline-block;
                    color: #004DFC;
                    padding-top: 17.5px;
                    padding-bottom: 17.5px;
                    border-bottom: 2px solid #E2E2E2;
                    width: 100%;
                    font-weight: 500;
                    cursor: pointer;
                    position: relative;
                    counter-increment: sections;
                    &::before {
                        content: counters(sections, ".") ". ";
                        display: inline-block;
                        margin-right: 5px;
                        color: #004DFC;
                    }
                    &:last-of-type {
                        border-bottom: none;
                        padding-bottom: 0px;
                    }
                }
            }
        }
        &-item {
            &-title {
                display: inline-block;
                color: #004DFC;
                padding-top: 17.5px;
                padding-bottom: 17.5px;
                border-bottom: 2px solid #E2E2E2;
                width: 100%;
                display: flex;
                justify-content: space-between;
                font-weight: 500;
                cursor: pointer;
                position: relative;
                &::after {
                    content: '';
                    max-width: 0%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #F4F4F4;
                    transition: .2s all ease-in-out;
                }
                &:hover {
                    &::after {
                        max-width: 100%;
                    }
                }
                &.no-arrow {
                    cursor: initial;
                    &:hover {
                        &::after {
                            max-width: 0%;
                        }
                    }
                    .course-single__toc-item-title-icon {
                        display: none;
                    }
                }
                &-icon {
                    position: relative;
                    z-index: 1;
                }
                &-text {
                    position: relative;
                    z-index: 1;
                    &::before {
                        content: counters(section, ".") ". ";
                        display: inline-block;
                        color: #004DFC;
                    }
                }
            }
            &-list {
                font-size: 14px;
                line-height: 25px;
                color: #7A7A7A;
                max-height: 0px;
                overflow: hidden;
                transition: .3s all ease-in-out;
            }
        }
        &-items {
            counter-reset: section;
            &-item {
                counter-increment: section;
                position: relative;
                &.active {
                    .course-single__toc-item-list {
                        max-height: 1000px;
                        padding-top: 17.5px;
                        padding-bottom: 22px;
                    }
                }
                &:first-of-type {
                    padding-top: 0px;
                }
                &:last-of-type {
                    .course-single__toc-item-title {
                        border-bottom: none;
                        padding-bottom: 0px;
                    }
                }
            }
        }
    }
    &__author {
        margin-bottom: 50px;
        &-text {
            &-name {
                font-size: 17px;
                line-height: 25px;
                font-weight: 700;
            }
        }
    }
    &__description {
        &-top {
            display: flex;
            justify-content: space-between;
            padding-bottom: 12.5px;
            margin-bottom: 26px;
            border-bottom: 2px solid #E2E2E2;
            @media(max-width: 650px) {
                flex-wrap: wrap;
            }
            &-text {
                font-size: 27px;
                font-weight: 700;
                @media(max-width: 650px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                    margin-bottom: 20px;
                }
            }
            &-info {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 600;
                line-height: 25px;
                @media(max-width: 650px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                }
                img {
                    margin-right: 8px;
                }
                &-time {
                    display: flex;
                    align-items: center;
                    margin-right: 28px;
                }
                &-episodes {
                    display: flex;
                    align-items: center;
                }
            }
        }
        &-bottom {
            font-size: 17px;
            line-height: 30px;
            margin-bottom: 40px;
        }
    }
    &__share {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 50px;
        @media(max-width: 650px) {
            flex-wrap: wrap;
        }
        a {
            color: #fff;
        }
        &-btn {
            background-color: #004DFC;
            padding: 29px 36px 25px 36px;
            font-size: 16px;
            line-height: 21px;
            font-weight: 700;
            color: #fff;
            max-height: 80px;
            border-radius: 6px;
            cursor: pointer;
            transition: .3s all ease-in-out;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            pointer-events: none;
            @media(min-width:1200px) {
                opacity: 1;
                pointer-events: all;
            }
            @media(max-width: 650px) {
                margin-bottom: 30px;
                display: none;
            }
            img {
                max-width: 18px;
                margin-right: 13px;
            }
            &:hover {
                background-color: #1A1B1C;
            }
        }
        &-socials {
            display: flex;
            align-items: center;
            @media(max-width: 650px) {
                max-width: 100%;
                flex: 0 0 100%;
            }
            &-text {
                font-size: 14px;
                line-height: 25px;
                font-weight: 600;
                margin-right: 24px;
            }
            &-item {
                margin-right: 18px;
                cursor: pointer;
                &:hover {
                    img {
                        transform: rotate(360deg);
                    }
                }
                img {
                    display: block;
                    transition: .3s all ease-in-out;
                }
            }
        }
    }
    &__preview {
        background-image: url('/assets-kursy/images/preview-image.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to top, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
        }
        &-wrapper {
            padding-top: 109px;
            padding-bottom: 99px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            position: relative;
            z-index: 3;
            &-icon {
                width: 124px;
                height: 124px;
                background-color: #004DFC;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 56px;
                img {
                    margin-left: 13px;
                }
            }
            &-text {
                font-size: 30px;
                font-weight: 700;
                color: #fff;
                max-width: 100%;
                width: 100%;
                text-align: center;
                z-index: 2;
                position: relative;
            }
        }
    }
    &__fulltext {
        h2 {
            font-size: 22px;
            line-height: 30px;
            font-weight: 700;
            padding-bottom: 13.5px;
            margin-bottom: 26px;
            margin-top: 65px;
            border-bottom: 2px solid #E2E2E2;
            width: 100%;
        }
        a.course-single__fulltext-purchase {
            color: #fff;
        }
        &-purchase {
            background-color: #004DFC;
            border-radius: 15px;
            padding: 37px;
            width: 100%;
            color: #fff;
            font-size: 20px;
            font-weight: 700;
            display: block;
            text-align: center;
            margin-bottom: 144px;
            cursor: pointer;
            transition: .3s all ease-in-out;
            &:hover {
                background-color: #1A1B1C;
            }
        }
        .gallery {
            margin-bottom: 37px;
            &__title {
                font-size: 22px;
                line-height: 30px;
                font-weight: 700;
                padding-bottom: 13.5px;
                margin-bottom: 26px;
                margin-top: 65px;
                border-bottom: 2px solid #E2E2E2;
                width: 100%;
            }
            &__image {
                display: block;
                margin-bottom: 30px;
            }
        }
        .selected-fragments {
            margin-bottom: 57px;
            &__part-title {
                font-size: 18px;
                line-height: 24px;
                margin: 10px 0;
                font-weight: 600;
            }
            &__item {
                cursor: pointer;
            }
            &__title {
                font-size: 22px;
                line-height: 30px;
                font-weight: 700;
                padding-bottom: 13.5px;
                margin-bottom: 26px;
                margin-top: 65px;
                border-bottom: 2px solid #E2E2E2;
                width: 100%;
            }
            &__image {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 19px;
                & > img {
                    box-shadow: $inputShadow;
                }
            }
            &__play {
                position: absolute;
                img {
                    display: block;
                }
            }
            &__text {
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 20px;
                font-weight: 600;
            }
        }
        .from-author {

        }
    }
}

.video-popup {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    left: -100%;
    background-color: #000;
    transition: .3s all;
    z-index: 100;
    .video {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        iframe {
            max-height: 100%;
            max-width: 100%;
        }
    }
    &.show {
        left: 0;
    }
    &__close {
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 15px;
        padding: 15px;
    }
}

.breadcrumb {
    font-size: 13px;
    font-weight: 500;
    line-height: 30px;
}
