.burger-menu {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    z-index: 50;
    background: $blueGradient;
    transition: .3s all;
    padding: 25px 15px;
    &.show {
        left: 0;
    }
    &__close {
        cursor: pointer;
    }
    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 45px;
    }
    &__list {
        font-size: 27px;
        line-height: 25px;
        font-weight: 700;
        color: #fff;
        &-item {
            margin-bottom: 17px;
        }
    }
}

.background-on-page {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(26,27,28,.33);
    width: 100vw;
    height: 100vh;
    z-index: 4;
    pointer-events: none;
    opacity: 0;
    transition: .3s all ease-in-out;
    &.show {
        opacity: 1;
        pointer-events: initial;
    }
}
.new-header {
    padding-top: 26px;
    margin-bottom: -86px;
    position: relative;
    background-color: transparent;
    transition: .3s all ease-in-out;
    background-color: rgba(255,255,255,0);
    &.menu-background {
        z-index: 4;
        background-color: rgba(255,255,255,1);
    }
    &__wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        font-family: $poppins;
        position: relative;
    }
    &__logo {
        display: flex;
        align-items: center;
        z-index: 1;
        &-avt {
            margin-right: 14px;
            box-shadow: 0 10px 20px rgba(213,28,24,.28);
        }
    }
    &__burger {
        &-icon {
            padding: 11px 8px;
            border-radius: 4px;
            background-color: #fff;
            box-shadow: 0 10px 20px rgba(0,0,0,.11);
            width: 35px;
            height: 35px;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            display: none;
            cursor: pointer;
            z-index: 1;
            @media(max-width: 600px) {
                display: flex;
            }
            &-line {
                width: 18px;
                height: 2px;
                background-color: #000;
                margin-bottom: 4px;
                border-radius: 4px;
                transition: .3s all ease-in-out;
            }
            &.active {
                .new-header__burger-icon-line {
                    &:first-of-type {
                        transform: translate(0,6px) rotate(45deg);
                    }
                    &:nth-of-type(2) {
                        opacity: 0;
                    }
                    &:last-of-type {
                        transform: translate(0,-6px) rotate(-45deg); 
                    }
                }
            }
        }
    }
    &__hidden-menu {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 10;
        font-family: $poppins;
        opacity: 0;
        pointer-events: none;
        transition: .3s all ease-in-out;
        &.show {
            opacity: 1;
            pointer-events: all;
        }
        &-wrapper {
            margin: 0 15px;
            padding-top: 27.5px;
            margin-top: 20px;
            border-top: 1px solid #E2E2E2;
        }
        &-go-back {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;
            img {
                margin-right: 10px;
            }
        }
        &-unlock-course {
            font-size: 15px;
            font-weight: 700;
            background-color: #004DFC;
            color: #fff;
            padding: 13px 25px 12px 25px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            box-shadow: 0 20px 50px rgba(0,77,252,0.16);
            transition: .3s all ease-in-out;
            max-height: 48px;
            max-width: 182px;
            margin: 0 auto 30px auto;
            &:hover {
                color: #fff;
                background-color: #1A1B1C;
            }
            svg {
                margin-right: 10px;
            }
        }
        &-my-courses {
            font-size: 15px;
            font-weight: 700;
            margin-bottom: 30px;
            text-align: center;
            width: 100%;
            color: #1A1B1C;
            cursor: pointer;
        }
        &-all-courses {
            font-size: 15px;
            font-weight: 700;
            margin-bottom: 30px;
            text-align: center;
            width: 100%;
            display: block;
            color: #1A1B1C;
            cursor: pointer;
        }
        &-contact {
            font-size: 15px;
            font-weight: 700;
            text-align: center;
            width: 100%;
            display: block;
            margin-bottom: 40px;
            color: #1A1B1C;
            cursor: pointer;
        }
        &-unlock-wrapper {
            position: absolute;
            background-color: #fff;
            top: 0;
            left: 0;
            width: 100%;
            min-height: 100%;
            padding: 27.5px 15px 170px 15px;
            margin-top: 20px;
            border-top: 1px solid #E2E2E2;
            opacity: 0;
            pointer-events: none;
            transition: .3s all ease-in-out;
            &-info {
                font-size: 15px;
                line-height: 22px;
                margin-bottom: 20px;
                &.open {
                    display: block;
                }
                &.error {
                    color: #ff0033;
                }
                &.success {
                    color: #28a745;
                }
            }
            &.show {
                pointer-events: all;
                opacity: 1;
            }
            &-title {
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 15px;
            }
            &-description {
                font-size: 15px;
                line-height: 27px;
                margin-bottom: 15px;
            }
            input[type="submit"] {
                border-radius: 4px;
                padding: 23px;
            }
        }
        &-my-courses-wrapper {
            position: absolute;
            background-color: #fff;
            top: 0;
            left: 0;
            width: 100%;
            min-height: 100%;
            padding: 27.5px 15px 170px 15px;
            margin-top: 20px;
            border-top: 1px solid #E2E2E2;
            opacity: 0;
            pointer-events: none;
            transition: .3s all ease-in-out;
            .new-header__btns-wrapper-list {
                li a {
                    justify-content: center;
                    font-size: 15px;
                    font-weight: 500;
                }
            }
            &.show {
                pointer-events: all;
                opacity: 1;
            }
            .new-header__btns-wrapper-btn {
                max-width: 240px;
                margin: 20px auto 28px auto;
                color: #fff;
            }
        }
    }
    &__btns {
        display: flex;
        position: relative;
        @media(max-width: 600px) {
            display: none;
        }
        &-wrapper {
            position: absolute;
            top: 51px;
            width: 360px;
            background-color: #fff;
            box-shadow: 0 40px 60px rgba(0,0,0,.16);
            padding: 22px 27px 29px 27px;
            z-index: 6;
            cursor: initial;
            opacity: 0;
            pointer-events: none;
            transition: .3s all ease-in-out;
            &::after {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 16px 16px 16px;
                border-color: transparent transparent #fff transparent;
                position: absolute;
                top: -12px;
                left: 50%;
                transform: translate(-50%);
            }
            &-no-courses {
                padding-top: 32px;
                position: relative;
                .new-header__btns-wrapper-close {
                    position: absolute;
                    right: 0;
                    top: 0;
                }
                &-text {
                    max-width: 250px;
                    text-align: center;
                    margin: 0 auto;
                }
            }
            &-top {
                display: flex;
                justify-content: space-between;
                padding-bottom: 14.5px;
                border-bottom: 1px solid #E2E2E2;
                margin-bottom: 20px;
            }
            &-close {
                cursor: pointer;
                img {
                    pointer-events: none;
                }
            }
            &-list {
                li {
                    margin-bottom: 22px;
                    a {
                        display: flex;
                        align-items: center;
                        padding: 11px 11px 11px 0;
                        img {
                            margin-right: 13px;
                        }
                    }
                }
            }
            a.new-header__btns-wrapper-btn {
                color: #fff;
            }
            &-btn {
                background-color: #004DFC;
                cursor: pointer;
                padding: 14px;
                text-align: center;
                color: #fff;
                font-size: 15px;
                font-weight: 700;
                margin-top: 36px;
                display: block;
                transition: .3s all ease-in-out;
                &:hover {
                    color: #fff;
                    background-color: #1A1B1C;
                }
            }
        }
        &-my-courses {
            font-size: 15px;
            font-weight: 700;
            color: #1A1B1C;
            box-shadow: 0 20px 50px rgba(0,0,0,.16);
            display: flex;
            justify-content: center;
            padding: 14px 25px;
            border-radius: 4px;
            cursor: pointer;
            transition: .3s all ease-in-out;
            margin-right: 15px;
            max-height: 48px;
            height: 100%;
            background-color: #fff;
            position: relative;
            line-height: 21px;
            z-index: 5;
            @media(max-width: 400px) {
                max-width: 100%;
                width: 100%;
                margin-bottom: 15px;
                margin-right: 0px;
            }
            &.active {
                background-color: #1A1B1C;
                box-shadow: 0 20px 50px rgba(0,77,252,.16);
                .new-header__btns-my-courses-text {
                    color: #fff;
                }
                .new-header__btns-wrapper {
                    opacity: 1;
                    top: 71px;
                    pointer-events: all;
                }
            }
            &-text {
                transition: .3s all ease-in-out;
            }
            &:hover {
                background-color: #1A1B1C;
                box-shadow: 0 20px 50px rgba(0,77,252,.16);
                .new-header__btns-my-courses-text {
                    color: #fff;
                }
            }
        }
        &-unlock-course {
            display: inline-block;
            font-size: 15px;
            font-weight: 700;
            background-color:  #004DFC;
            color: #fff;
            padding: 13px 25px 12px 25px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            box-shadow: 0 20px 50px rgba(0,77,252,.16);
            transition: .3s all ease-in-out;
            max-height: 48px;
            z-index: 1;
            @media(max-width: 400px) {
                max-width: 100%;
                width: 100%;
            }
            &:hover {
                background-color: #1A1B1C;
            }
            svg {
                margin-right: 9px;
                path {
                    transition: .3s all ease-in-out;
                }
            }
        }
    }
}

.unlock-course-popup {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $poppins;
    background-color: rgba(26,27,28, .64);
    backdrop-filter: blur(16px);
    z-index: 10;
    transition: .3s all ease-in-out;
    opacity: 0;
    pointer-events: none;
    font-family: $poppins;
    &.show {
        left: 0;
        opacity: 1;
        pointer-events: all;
    }
    &__wrapper {
        max-width: 832px;
        background-color: #fff;
        border-radius: 16px;
        z-index: 7;
        display: flex;
        border-radius: 16px;
        box-shadow: 0 40px 60px rgba(0,0,0,.47);
        @media(max-width: 850px) {
            flex-wrap: wrap;
            border-radius: 0px;
        }
    }
    &__left {
        max-width: 307px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(135deg, rgba(50,50,72,1) 0%,rgba(12,12,12,1) 100%);
        border-top-left-radius: 14px;
        border-bottom-left-radius: 14px;
        @media(max-width: 850px) {
            max-width: 100%;
            width: 100%;
            padding: 85px 0;
            border-radius: 0px;
        }
        img {
            position: absolute;
            z-index: 8;
        }
        &-circle {
            width: 96px;
            height: 96px;
            border-radius: 50%;
            background-color: #004DFC;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            &::after {
                content: '';
                width: 146px;
                height: 146px;
                border-radius: 50%;
                position: absolute;
                background-color: rgba(0,77,252,.2);
                animation: resizeCirclesAfter 2s linear infinite;
            }
            &::before {
                content: '';
                width: 207px;
                height: 207px;
                border-radius: 50%;
                position: absolute;
                background-color: rgba(0,77,252,.2);
                animation: resizeCirclesBefore 2s linear infinite;
            }
        }
        @keyframes resizeCirclesAfter {
            0% {
                width: 96px;
                height: 96px;
            }
            50% {
                width: 146px;
                height: 146px;
            }
            100% {
                width: 96px;
                height: 96px;
            }
        }
        @keyframes resizeCirclesBefore {
            0% {
                width: 146px;
                height: 146px;
            }
            50% {
                width: 207px;
                height: 207px;
            }
            100% {
                width: 146px;
                height: 146px;
            }
        }
    }
    &__right {
        max-width: calc(100% - 307px);
        width: 100%;
        padding: 22px 52px 51px 47px;
        @media(max-width: 850px) {
            max-width: 100%;
            width: 100%;
        }
        &-description-info {
            font-size: 15px;
            line-height: 22px;
            margin-top: -10px;
            margin-bottom: 20px;
            &.open {
                display: block;
            }
            &.error {
                color: #ff0033;
            }
            &.success {
                color: #28a745;
            }
        }
        &-top {
            padding-bottom: 16.5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #E2E2E2;
            margin-bottom: 34px;
            &-title {
                font-size: 16px;
                font-weight: 700;
            }
            &-close {
                cursor: pointer;
                &:hover {
                    img {
                        transform: rotate(360deg);
                    }
                }
                img {
                    pointer-events: none;
                    transition: .3s all ease-in-out;
                }
            }
        }
        &-description {
            font-size: 15px;
            line-height: 27px;
            margin-bottom: 39px;
            a {
                color: #004DFC;
            }
        }
        &-code {
            margin-bottom: 21px;
            position: relative;
            input {
                box-shadow: none;
                border-radius: 0px;
                font-family: $poppins;
                text-align: center;
                border: 2px solid #E2E2E2;
                margin-bottom: 0px;
                letter-spacing: 0.274em;
                font-family: $poppins;
                color: #1A1B1C;
                font-weight: 500;
                font-size: 20px;
            }
        }
        input[type="submit"] {
            font-family: $poppins;
            padding: 22px 0;
            border-radius: 4px;
        }
        &-btn {
            background-color: #004DFC;
            box-shadow: none;
            border: none;
            color: #fff;
            font-size: 18px;
            font-weight: 700;
            font-family: $poppins;
            transition: .3s all ease-in-out;
            &:hover {
                background-color: #1A1B1C;
                color: #fff;
            }
        }
    }
}