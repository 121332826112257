@font-face {
    font-family: 'Roboto';
    src: url('https://cdn.avt.pl/fonts/Roboto-Regular.eot');
    src: url('https://cdn.avt.pl/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('https://cdn.avt.pl/fonts/Roboto-Regular.woff2') format('woff2'),
    url('https://cdn.avt.pl/fonts/Roboto-Regular.ttf') format('ttf'),
    url('https://cdn.avt.pl/fonts/Roboto-Regular.woff') format('woff'),
    url('https://cdn.avt.pl/fonts/Roboto-Regular.svg') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('https://cdn.avt.pl/fonts/Roboto-Medium.eot');
    src: url('https://cdn.avt.pl/fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
    url('https://cdn.avt.pl/fonts/Roboto-Medium.woff2') format('woff2'),
    url('https://cdn.avt.pl/fonts/Roboto-Medium.ttf') format('ttf'),
    url('https://cdn.avt.pl/fonts/Roboto-Medium.woff') format('woff'),
    url('https://cdn.avt.pl/fonts/Roboto-Medium.svg') format('svg');
    font-weight: 500;
    font-style: normal;
}


// Bold Roboto


@font-face {
    font-family: 'Roboto';
    src: url('https://cdn.avt.pl/fonts/Roboto-Bold.eot');
    src: url('https://cdn.avt.pl/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
    url('https://cdn.avt.pl/fonts/Roboto-Bold.woff2') format('woff2'),
    url('https://cdn.avt.pl/fonts/Roboto-Bold.ttf') format('ttf'),
    url('https://cdn.avt.pl/fonts/Roboto-Bold.woff') format('woff'),
    url('https://cdn.avt.pl/fonts/Roboto-Bold.svg') format('svg');
    font-weight: 700;
    font-style: normal;
}

$color-main: #25aaa6;

.video-wrapper {
    width: 100%;
    padding: 0 15px;
    max-width: 1280px;
    margin: 0 auto;
}

// Dark
.custom-video[data-player="player"] {
    background-color: #000000;
    color: #ffffff;
    [data-player="toc"] {
        background-color: #202020;
    }
    [data-player="toc-top"] {
        &:after {
            background-color: rgba(255,255,255,0.35);
        }
    }
}


.custom-video[data-player="player"] {
    width: 100%;
    display: block;
    padding: 0;
    position: relative;
    font-family: 'Roboto', sans-serif;
    counter-reset: movie;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    @media(min-width: $lg) {
        overflow: hidden;
    }
    &, * {
        user-select: none;
    }

    [data-player="video-text"] {
        display: none;
    }
    [data-player="toc"] {
        position: relative;
        z-index: 5;
        transition: top .2s ease-in-out;
        width: 100%;
        height: 500px;
        @media(min-width: $lg) {
            height: 100%;
            right: 0;
            top: calc(100% - 82px);
            position: absolute;
            max-width: 450px;
        }
        &[data-open="true"] {
            top: 0;
        }
        &:after {
            content: "";
            position: absolute;
            pointer-events: none;
            top: auto;
            height: 80px;
            left: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
            background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%); /* w3c */
            @media(min-width: $lg) {
                opacity: 1;
            }
        }
    }
    .open,
    [data-active="true"] {
        [data-player="movie-toc-btn"] {
            transform: rotate(180deg);
        }
    }
    [data-player="movie-toc-btn"] {
        position: absolute;
        top: 15px;
        right: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("/assets-kursy/images/chevron-up.svg");
        width: 30px;
        height: 30px;
        cursor: pointer;
        z-index: 3;
        transform-origin: 50% 50%;
        transition: transform .2s ease-in-out;
    }
    [data-player="movie-toc-count"],
    [data-player="movie-active-toc"] {
        font-size: 13px;
        opacity: 0.65;
        margin-top: 2px;
    }
    [data-player="toc-top"] {
        height: 82px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        padding-left: 28px;
        position: relative;
        padding-right: 50px;
        &:after {
            content: "";
            left: 0;
            right: 0;
            top: 100%;
            height: 1px;
            display: block;
            position: absolute;
        }
    }
    [data-player="list-wrapper"] {
        -ms-overflow-style: none;
        scrollbar-width: none;
        width: calc(100% + 20px);
        height: 100%;
        padding: 15px 65px 15px 30px;
        @media(min-width: $lg) {
            padding: 15px 65px 0 30px;
            height: calc(100% - 164px);
        }
        &::-webkit-scrollbar {
            display: none;
            width: 0px;
            background: transparent;
        }
        .simplebar-track {
            right: 36px;
            top: 20px;
            bottom: 15px;
            @media(min-width: $lg) {
                bottom: 0;
            }
            &:after {
                content: "";
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                width: 1px;
                background-color: #707070;
                position: absolute;
                z-index: -5;
            }
            .simplebar-scrollbar {
                background-color: #585858;
                &:before {
                    background-color: #585858;
                }
            }
        }
    }
    [data-player="player-title"] {
        font-size: 15px;
        font-weight: 500;
        width: 100%;
    }
    [data-player="player-counter"] {
        font-size: 13px;
        font-weight: 400;
        opacity: 0.65;
    }
    @keyframes open {
        from {height: 0%;}
        to {height: 100%;}
    }
    [data-player="movie-toc"] {
        width: 100%;
        display: none;
        transition: opacity .2s ease-in-out, right .2s ease-in-out;
        height: 0%;
        &.open {
            display: block;
            animation: open .2s linear;
        }
    }
    [data-player="list-controls"] {
        height: 82px;
        display: flex;
        align-items: center;
        align-content: center;
        padding-left: 44px;
        position: absolute;
        top: 0;
        right: 15px;
        @media(min-width: $lg) {
            position: static;
        }
    }
    [data-player="toc-list"] {
        margin-top: 13px;
    }
    [data-player="toc-item"] {
        display: flex;
        flex-wrap: wrap;
        padding: 5px 0;
        cursor: pointer;
        position: relative;
        font-size: 13px;
        &:after {
            content: "";
            position: absolute;
            left: -30px;
            right: -10px;
            top: 0;
            bottom: 0;
            background-color: rgba(255,255,255,0.25);
            opacity: 0;
            transition: opacity .2s ease-in-out;
        }
        &:hover {
            &:after {
                opacity: 1;
            }
        }
    }
    [data-player="toc-title"] {
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        text-align: center;
        border-bottom: 1px solid rgba(255,255,255,0.4);
        margin-bottom: 15px;
        letter-spacing: 0.05em;
    }
    [data-player="toc-item-time"] {
        text-align: right;
        min-width: 50px;
        pointer-events: none;
    }
    [data-player="toc-item-separator"] {
        display: none;
        text-align: center;
        pointer-events: none;
    }
    [data-player="toc-item-title"] {
        width: 100%;
        max-width: calc(100% - 50px);
        pointer-events: none;
    }
    &[data-movie-description="true"] {
        [data-player="video-text"] {
            pointer-events: none;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(0,0,0,0.4);
            transition: opacity .2s ease-in-out;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            align-content: flex-end;
            color: inherit;
            z-index: 3;
            padding: 15px 15px 30px;
            @media(min-width: $lg) {
                padding: 0 30px 135px;
                position: absolute;
            }
            @media(min-width: $lg) {
                padding: 0 480px 135px 30px;
            }
        }
        &[data-play="true"] {
            @media(min-width: $lg) {
                [data-player="video-text"] {
                    opacity: 0;
                }
            }
        }
    }
    &[data-play="true"] {
        [data-player="play"] {
            &:after {
                background-image: url("/assets-kursy/images/pause.svg");
            }
            &:before {
                content: "Pauza";
                left: 12px;
            }
        }
        [data-active="true"] {
            [data-player="movie-active-toc"] {
                display: block;
            }
            [data-player="movie-toc-count"] {
                display: none;
            }
        }
        [data-player="video-wrapper"]:after {
            opacity: 0;
        }
        @media(min-width: $lg) {
            [data-player="list-btn"] {
                bottom: -36px;
            }
            &:hover {
                [data-player="list-btn"] {
                    bottom: 36px;
                }
            }
        }
    }
    &[data-fullscreen="false"][data-play="true"] {
        @media(min-width: $lg) {
            &:hover {
                [data-player="controls-wrapper"] {
                    bottom: 0;
                }
                [data-player="toc-wrapper"] {
                    right: 0;
                }
                [data-player="toc"] {
                    top: calc(100% - 82px);
                }
                [data-player="toc"][data-open="true"] {
                    top: 0;
                }
            }
            [data-player="controls-wrapper"] {
                bottom: -90px;
            }
            [data-player="toc-wrapper"] {
                right: -100%;
            }
            [data-player="toc"] {
                top: 100%;
            }
        }
    }
    &[data-fullscreen="true"] {
        overflow-x: hidden;
        overflow-y: hidden;
        overflow: hidden;
        [data-player="controls-wrapper"] {
            .custom-video__controls-left {
                @media(max-width: $sm-max) {
                    bottom: 15px;
                }
            }
        }
        [data-player="video-wrapper"] {
            width: 100%;
            height: 100%;
            padding: 0;
            background-color: #000000;
        }
        @media(max-width: $md-max) {
            [data-player="video-text"] {
                display: none;
            }
            [data-player="toc"] {
                display: none !important;
            }
            [data-player="controls-wrapper"] {
                bottom: -100%;
                top: auto;
            }
            &[data-screen-move="true"] {
                [data-player="controls-wrapper"] {
                    bottom: 0;
                    top: auto;
                }
            }
        }
        @media(min-width: $lg) {
            [data-player="controls-wrapper"] {
                bottom: -90px;
            }
            [data-player="toc"] {
                top: 100%;
            }
            [data-player="list-btn"] {
                bottom: -36px;
            }
            &[data-screen-move="false"] {
                cursor: none;
                [data-player="toc"] {
                    top: 100%;
                }
                [data-player="list-btn"] {
                    bottom: -36px;
                }
            }
            &[data-screen-move="true"] {
                [data-player="controls-wrapper"] {
                    bottom: 0;
                }
                &:hover {
                    [data-player="list-btn"] {
                        bottom: 36px;
                    }
                    [data-player="toc"] {
                        top: calc(100% - 82px);
                    }
                    [data-player="toc"][data-open="true"] {
                        top: 0;
                    }
                }
            }
        }
    }
    [data-player="toc-counter"] {
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: #AAAAAA;
        font-weight: 700;
        width: 100%;
        margin-top: 8px;
        font-size: 12px;
        @media(min-width: $md) {
            font-size: 17px;
            margin-top: 30px;
        }
    }
    [data-player="toc-active-title"] {
        font-weight: 400;
        width: 100%;
        font-size: 13px;
        @media(min-width: $md) {
            font-size: 22px;
        }
    }
    [data-player="movie-active-toc"] {
        display: none;
    }
    [data-player="video-counter"] {
        font-weight: 700;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #AAAAAA;
        font-size: 12px;
        @media(min-width: $md) {
            font-size: 20px;
        }
    }
    [data-player="video-title"] {
        width: 100%;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
        @media(min-width: $md) {
            font-size: 35px;
            font-weight: 700;
        }
    }
    [data-player="video-description"] {
        font-size: 24px;
        font-weight: 500;
        width: 100%;
        max-width: 650px;
        display: none;
    }
    [data-player="play-list"] {
        width: 20px;
        height: 20px;
        cursor: pointer;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("/assets-kursy/images/play-list.svg");
        opacity: 0.4;
        position: relative;
        z-index: 3;
        &:after {
            content: "Odtwarzaj listę";
            opacity: 1;
            position: absolute;
            font-size: 13px;
            font-weight: 400;
            color: #ffffff;
            white-space: nowrap;
            background-color: rgba(0,0,0,0.8);
            padding: 4px 6px;
            left: -110px;
            opacity: 1;
            transition: opacity .2s ease-in-out;
            top: -8px;
            display: none;
            @media(min-width: $sm) {
                display: block;
            }
            @media(min-width: $lg) {
                top: auto;
                opacity: 0;
                bottom: calc(100% + 11px);
                left: -36px;
                pointer-events: none;
            }
        }
        &:hover {
            &:after {
                opacity: 1;
            }
        }
        &[data-active="true"] {
            opacity: 1;
        }
    }
    [data-player="controls-wrapper"] {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        background-color: transparent;
        transition: bottom .2s ease-in-out, top .2s ease-in-out;
        position: absolute;
        z-index: 5;
        top: 0;
        padding-bottom: 56.25%;
        @media(min-width: $md) {
            top: auto;
            min-height: 82px;
            margin-top: -82px;
            padding-bottom: 0;
        }
        @media(min-width: $lg) {
            top: auto;
            bottom: 0;
        }
        .custom-video__controls-left {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            min-height: 82px;
            position: absolute;
            align-items: flex-end;
            align-content: flex-end;
            top:0;
            left: 0;
            right: 0;
            padding: 0 10px 10px;
            bottom: 0;
            @media(min-width: $md) {
                padding: 0 30px;
                align-items: center;
                align-content: center;
            }
            @media(min-width: $lg) {
                position: static;
                max-width: calc(100% - 450px);
            }
            &:after {
                @media(min-width: $md) {
                    content: "";
                    position: absolute;
                    pointer-events: none;
                    top: auto;
                    height: 80px;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    opacity: 1;
                    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%); /* w3c */
                }
            }
        }
        .custom-video__controls-right {
            width: 100%;
            max-width: 450px;
        }
    }
    [data-player="video"] {
        max-width: 100%;
        width: 100%;
        height: 100%;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
    }
    [data-player="video-wrapper"] {
        font-size: 0;
        color: inherit;
        position: relative;
        padding-bottom: 56.25%;
        @media(min-width: $lg) {
            padding-bottom: 0;
            height: 100%;
        }
        &:after {
            content: "";
            position: absolute;
            pointer-events: none;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            opacity: 1;
            background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%); /* w3c */
            transition: opacity .2s ease-in-out;
        }
    }

    [data-player="next"],
    [data-player="prev"] {
        width: 32px;
        height: 32px;
        margin-right: 16px;
        cursor: pointer;
        background-position: center;
        background-repeat: no-repeat;
        transform-origin: 50% 50%;
        display: none;
        z-index: 9;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        @media(min-width: $md) {
            left: auto;
            right: auto;
            top: auto;
            bottom: auto;
            position: relative;
            margin: 0 16px 0 0;
        }
        &:after {
            position: absolute;
            font-size: 13px;
            font-weight: 400;
            color: #ffffff;
            white-space: nowrap;
            background-color: rgba(0,0,0,0.8);
            padding: 4px 6px;
            left: -21px;
            bottom: calc(100% + 11px);
            opacity: 0;
            transition: opacity .2s ease-in-out;
            pointer-events: none;
            display: none;
            @media(min-width: $lg) {
                display: block;
            }
        }
        &:hover {
            &:after {
                opacity: 1;
            }
        }
    }
    [data-player="prev"] {
        background-image: url("/assets-kursy/images/prev.svg");
        @media(max-width: $sm-max) {
            right: calc(50% + 60px);
            left: auto;
        }
        &:after {
            content: "Poprzedni";
            left: -15px;
        }
    }
    [data-player="next"] {
        background-image: url("/assets-kursy/images/next.svg");
        @media(max-width: $sm-max) {
            right: auto;
            left: calc(50% + 60px);

        }
        &:after {
            content: "Następny";
            left: -20px;
        }
    }
    [data-player="fullscreen-btn"] {
        width: 32px;
        height: 32px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        cursor: pointer;
        background-image: url("/assets-kursy/images/maximize.svg");
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 0;
        margin-left: auto;
        justify-self: flex-end;
        position: relative;
        z-index: 9;
        &:after {
            content: "Pełny ekran";
            opacity: 1;
            position: absolute;
            font-size: 13px;
            font-weight: 400;
            color: #ffffff;
            white-space: nowrap;
            background-color: rgba(0,0,0,0.8);
            padding: 4px 6px;
            left: -24px;
            bottom: calc(100% + 11px);
            opacity: 0;
            transition: opacity .2s ease-in-out;
            pointer-events: none;
            display: none;
            @media(min-width: $lg) {
                display: block;
            }
        }
        &:hover {
            &:after {
                opacity: 1;
            }
        }
    }
    [data-player="list-movie"] {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        padding-bottom: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        border-bottom: 1px solid rgba(112,112,112,0.33);
        position: relative;
        &:before {
            counter-increment: movie;
            content: counter(movie);
            position: absolute;
            right: calc(100% + 10px);
            top: 13px;
            font-size: 15px;
        }
        &:last-child {
            border-bottom: none;
        }
        &[data-active="true"] {
            [data-player="movie-toc"] {
                height: auto;
                display: block;
            }
        }
        &[data-active="true"][data-toc="false"] {
            &:after {
                content: "";
                position: absolute;
                left: -30px;
                right: -10px;
                top: -10px;
                bottom: 0;
                background-color: rgba(255,255,255,0.25);
                opacity: 1;
                -webkit-transition: opacity .2s ease-in-out;
                transition: opacity .2s ease-in-out;
                z-index: -1;
            }
        }
        [data-player="list-movie-image"] {
            max-width: 100px;
            height: auto;
            display: block;
        }
        [data-player="list-image-wrap"] {
            position: relative;
        }
        [data-player="list-movie-text"] {
            max-width: calc(100% - 130px);
            padding-left: 16px;
        }
        [data-player="list-movie-title"] {
            font-size: 15px;
            line-height: 18px;
            font-weight: 500;
        }
        [data-player="list-movie-description"] {
            font-size: 15px;
            display: none;
        }
        [data-player="list-movie-duration"] {
            position: absolute;
            bottom: 0;
            right: 0;
            font-size: 12px;
            padding: 2px 3px;
            background-color: rgba(0,0,0,0.8);
            font-weight: 500;
        }
    }
    [data-player="list"] {
        background-color: inherit;
    }
    [data-player="pause"] {
        position: relative;
        margin-right: 16px;
        cursor: pointer;
        transition: opacity .2s ease-in-out;
        width: 27px;
        height: 27px;
        @media(min-width: 460px) {
            width: 32px;
            height: 32px;
        }
        &:after, &:before {
            content: "";
            position: absolute;
            height: 100%;
            top: 0;
            width: 45%;
            background-color: $color-main;
            display: block;
            border-radius: 6px;
        }
        &:after {
            right: 0;
        }
    }
    [data-player="list"] {
        display: none;
    }
    [data-player="list-btn"] {
        width: 18px;
        height: 14px;
        background-image: url("/assets-kursy/images/list.svg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
        position: absolute;
        z-index: 9;
        right: 36px;
        bottom: 34px;
        transition: bottom .2s ease-in-out;
        display: none;
        @media(min-width: $lg) {
            display: block;
        }
        &:after {
            content: "Pokaż playlistę";
            opacity: 1;
            position: absolute;
            font-size: 13px;
            font-weight: 400;
            color: #ffffff;
            white-space: nowrap;
            background-color: rgba(0,0,0,0.8);
            padding: 4px 6px;
            left: -44px;
            bottom: calc(100% + 11px);
            opacity: 0;
            transition: opacity .2s ease-in-out;
            pointer-events: none;
        }
        &:hover {
            &:after {
                opacity: 1;
            }
        }
        &[data-open="true"] {
            &:after {
                content: "Ukryj playlistę";
                left: -40px;
            }
        }
    }
    [data-player="play"] {
        position: absolute;
        cursor: pointer;
        margin-right: 20px;
        transition: opacity .2s ease-in-out;
        z-index: 9;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 40px;
        height: 40px;
        @media(min-width: $md) {
            width: 65px;
            height: 65px;
            position: relative;
            top: auto;
            left: auto;
            bottom: auto;
            right: auto;
            margin: 0 16px 0 0;
        }
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 5px;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 16px;
            height: 20px;
            background-image: url("/assets-kursy/images/play.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
        &:before {
            content: "Odtwarzaj";
            position: absolute;
            font-size: 13px;
            font-weight: 400;
            color: #ffffff;
            white-space: nowrap;
            background-color: rgba(0,0,0,0.8);
            padding: 4px 6px;
            left: 2px;
            top: -30px;
            opacity: 0;
            transition: opacity .2s ease-in-out;
            pointer-events: none;
            display: none;
            @media(min-width: $lg) {
                display: block;
            }
        }
        &:hover {
            &:before {
                opacity: 1;
            }
        }
    }
    [data-player="progress-wrap"] {
        height: 2px;
        flex-grow: 2;
        z-index: 9;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        @media(min-width: $md) {
            width: 100%;
            left: auto;
            right: auto;
            position: relative;
            top: -8px;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: #1E1E1E;
            transition: opacity .2s ease-in-out, top .2s ease-in-out, bottom .2s ease-in-out;
        }
        &:after {
            content: "";
            left: 0;
            right: 0;
            cursor: pointer;
            position: absolute;
            background-color: $color-main;
            opacity: 0;
            top: -4px;
            bottom: -4px;
        }
        &:hover {
            &:before {
                top: -2px;
                bottom: -2px;
            }
            [data-player="progress-bar"] {
                top: -2px;
                bottom: -2px;
            }
            [data-player="progress-thumb"] {
                top: -3px;
            }
        }
    }
    [data-player="progress-bar"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        background-color: $color-main;
        bottom: 0;
        transition: top .2s ease-in-out, bottom .2s ease-in-out;
    }

    [data-player="progress-thumb"] {
        position: absolute;
        top: -5px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background-color: #ffffff;
        cursor: pointer;
        transition: top .2s ease-in-out;
        z-index: 3;
        right: -9px;
    }
    [data-player="time"] {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        opacity: 0.82;
        margin-right: 25px;
        align-items: center;
        position: relative;
        z-index: 9;
    }
    [data-player="time-separator"] {
        margin: 0 2px;
    }
    [data-player="volume"] {
        display: none;
        align-items: center;
        align-content: center;
        position: relative;
        z-index: 9;
        transition: padding-left .2s ease-in-out;
        margin-right: 15px;
        @media(min-width: $md) {
            display: flex;
        }
    }
    [data-player="volume-icon"] {
        height: 18px;
        width: 24px;
        background-position: left center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("/assets-kursy/images/volume.svg");
        margin-right: 25px;
        position: relative;
        cursor: pointer;
        &[data-muted="true"] {
            [data-player="muted-icon"] {
                display: block;
            }
        }
    }
    [data-player="muted-icon"] {
        display: none;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 3;
        background-color: rgba(0,0,0,0.7);
        &:after,
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            height: 4px;
            border-radius: 2px;
            background-color: #ffffff;
            width: 100%;
            transform: rotate(45deg);
            z-index: 3;
        }
        &:after {
            transform: rotate(-45deg);
            z-index: 0;
            box-shadow: 0 0 10px rgba(0,0,0,0.7);
        }
    }
    &[data-audio="false"] {
        [data-player="muted-icon"] {
            display: block;
            cursor: default;
            pointer-events: none;
        }
        [data-player="volume"] {
            pointer-events: none;
            cursor: default;
        }
        [data-player="volume-bar"] {
            width: 0 !important;
        }
        [data-player="volume-wrap"] {
            opacity: 0.3;
            cursor: default;
            pointer-events: none;
        }
    }
    [data-player="volume-wrap"] {
        position: relative;
        width: 90px;
        height: 4px;
        border-radius: 6px;
        transition: width .2s ease-in-out;
        background-color: #1E1E1E;
        z-index: 9;
        &:before {
            content: "";
            position: absolute;
            top: -10px;
            bottom: -10px;
            left: 0;
            right: 0;
            display: block;
            cursor: pointer;
        }
    }
    [data-player="volume-bar"] {
        position: absolute;
        top: auto;
        bottom: 0;
        height: 100%;
        left: 0;
        width: 0;
        background-color: #ffffff;;
        pointer-events: none;
        &:after {
            content: "";
            width: 10px;
            right: -5px;
            height: 10px;
            top: -3px;
            border-radius: 50%;
            position: absolute;
            background-color: #ffffff;;
        }
    }
    [data-player="list-movie"][data-active="true"] {
        @for $i from 0 to 100 {
            [data-player="toc-list"][data-active-index='#{$i}'] {
                [data-index='#{$i}'] {
                    &:before {
                        content: "";
                        position: absolute;
                        width: 8px;
                        height: 10px;
                        background-image: url("/assets-kursy/images/play.svg");
                        left: -20px;
                        background-size: cover;
                        background-repeat: no-repeat;
                        top: 13px;
                    }
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
