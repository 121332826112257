.new-footer {
    margin-top: 160px;
    &-wrapper {
        font-family: $poppins;
        background-color: #0F1215;
        border-radius: 32px;
        padding: 81px 71px 81px 62px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media(max-width: 600px) {
            padding: 30px;
        }
        @media(max-width: 500px) {
            flex-wrap: wrap;
        }
        @media(max-width: 400px) {
            padding: 15px;
        }
    }
    &__logo {
        @media(max-width: 500px) {
            max-width: 100%;
            flex: 0 0 100%;
            order: 2;
            img {
                margin: 0 auto;
                display: block;
            }
        }
    }
    &__menu {
        font-size: 16px;
        color: rgba(255,255,255,.66);
        @media(max-width: 500px) {
            max-width: 100%;
            flex: 0 0 100%;
            order: 1;
        }
        &-list {
            @media(max-width: 500px) {
                display: flex;
                justify-content: center;
            }
            &-item {
                display: inline-block;
                @media(max-width: 500px) {
                    margin: 0 20px 30px 20px;
                }
                &:first-of-type {
                    margin-right: 40px;
                    @media(max-width: 500px) {
                        margin-right: 0px;
                    }
                }
            }
        }
    }
}