// Fonts:

$ubuntu: 'Ubuntu', sans-serif;
$lato: 'Lato', sans-serif;
$poppins: 'Poppins', sans-serif;

// Shadows:

$inputShadow: 0 3px 6px rgba(0,0,0,.35);
$btnShadow: 0 3px 6px rgba(0,0,0,.44);
$imageShadow: 0 3px 12px rgba(0,0,0,.47);

// Gradients:

$blueGradient:  linear-gradient(to right, rgba(17,117,103,1) 0%,rgba(37,170,166,1) 100%);
$hoverGradient: linear-gradient(to right, #2ECFC9 0%,#2CC8B2 100%);

// Borders:

$formBorder: 1px solid #D8D8D8;
$lightBorder: 1px solid rgba(112,112,112,.3);

// Colors:

$color-gray-border: rgba(112,112,112,.3);

// Breakpoints

$xs-max:    575.98px;
$sm:        576px;
$sm-max:    767.98px;
$md:        768px;
$md-max:    1024.98px;
$lg:        1025px;
$lg-max:    1279.98px;
$xl:        1280px;
